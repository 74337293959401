import gql from "graphql-tag";

export default class AccountQuery {
  static signup() {
    return gql`
      mutation CreatorCreate($email: String!, $password: String!) {
        CreatorCreate(email: $email, password: $password) {
          message
          status
        }
      }
    `;
  }

  static login() {
    return gql`
      mutation CreatorLogin($email: String!, $password: String!) {
        CreatorLogin(email: $email, password: $password) {
          status
          authorization {
            token
            type
            expires_in
          }
        }
      }
    `;
  }

  static getInfo() {
    return gql`
      query CreatorGetOwnData {
        CreatorGetOwnData {
          id
          created_at
          email
          role
          deals_taken
          last_seen
          first_name
          last_name
          gender {
            id
            name
          }
          applicants {
            campaign_id
            status
          }
          onboard_position
          status
          phone
          area_code
          dob
          address_street_1
          address_street_2
          address_city
          address_state
          address_zip_code
          address_country_code
          profile_picture_image
          sm_tiktok_url
          sm_instagram_url
          sm_youtube_url
          sm_amazon_url
          categories {
            name
            id
          }
          certifications {
            creator_id
            status
            type
            file_url
            video {
              actions
              best_video_resolution
              conversion_type
              duration
              error_data
              fps
              height
              orientation
              poster_4k
              poster_custom
              poster_full_hd
              poster_hd
              poster_preview
              requeue
              status
              video_4k
              video_custom
              video_full_hd
              video_hd
              video_preview
              video_source
              width
            }
          }
          has_verified_email
          email_verified_at
          suspended
          softban
          approved
          is_favorite
          is_hidden
          subcategories {
            id
            name
            category_id
            need_validation
          }
          rating
          tier
          about_me
          earningsWallet {
            user_id
            balance
          }
          reimbursementWallet {
            user_id
            balance
          }
          pending_admin_review
          worked_with
          paypal_email
        }
      }
    `;
  }

  static onBoardStep1() {
    return gql`
      mutation CreatorOnboardPositionOne(
        $firstName: String!
        $lastName: String!
        $phone: String
        $areaCode: String
        $gender: String
        $dob: String
        $category_id_1: ID!
        $category_id_2: ID!
        $category_id_3: ID!
        $file: Upload
        $address1: String
        $address2: String
        $city: String
        $state: String
        $zipcode: String
        $countryCode: String
      ) {
        CreatorOnboardPositionOne(
          first_name: $firstName
          last_name: $lastName
          phone: $phone
          area_code: $areaCode
          gender: $gender
          dob: $dob
          category_id_1: $category_id_1
          category_id_2: $category_id_2
          category_id_3: $category_id_3
          file: $file
          address_street_1: $address1
          address_street_2: $address2
          address_city: $city
          address_state: $state
          address_zip_code: $zipcode
          address_country_code: $countryCode
        ) {
          status
          message
        }
      }
    `;
  }

  static resendVerificationCode() {
    return gql`
      mutation CreatorResendEmailVerificationCode {
        CreatorResendEmailVerificationCode {
          status
          message
        }
      }
    `;
  }

  static verificationCode() {
    return gql`
      mutation CreatorEmailVerificationByCode($code: String!) {
        CreatorEmailVerificationByCode(email_validation_code: $code) {
          status
          message
        }
      }
    `;
  }

  static requestResetPassword() {
    return gql`
      mutation RequestResetPassword($email: String) {
        RequestResetPassword(email: $email, type: "creator") {
          status
          message
        }
      }
    `;
  }

  static resetPassword() {
    return gql`
      mutation ResetPasswordMutation(
        $email: String
        $token: String
        $password: String
        $confirmPassword: String
      ) {
        ResetPasswordMutation(
          email: $email
          token: $token
          password: $password
          password_confirmation: $confirmPassword
        ) {
          status
          message
        }
      }
    `;
  }
}
