import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "reviews.meta", layout: "AuthenticatedLayout" },
    children: [
      {
        path: "reviews",
        name: "ReviewsListing",
        component: () => import("@/modules/reviews/views/ReviewsListing.vue"),
      },
    ],
  },
];
