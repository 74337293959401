import { ref } from "vue";
import AuthService from "./AuthService";
import { provideApolloClient, useApolloClient } from "@vue/apollo-composable";
import AccountQuery from "@/modules/authentication/queries/AccountQuery";
import authenticatedService from "@/services/AuthenticatedService";
import type { Creator, CreatorDataType } from "@/types/AccountTypes";
import { useRouter } from "vue-router";

const accountInfo = ref<Creator | null>(null);

export default function useAccountHandler() {
  const router = useRouter();

  const getInfo = async (update = false) => {
    if (!accountInfo.value || update) {
      provideApolloClient(authenticatedService);
      const { resolveClient } = useApolloClient();
      const client = resolveClient();

      const { data } = await client.query<CreatorDataType>({
        query: AccountQuery.getInfo(),
        fetchPolicy: "no-cache",
      });
      accountInfo.value = data.CreatorGetOwnData;
      //   if (import.meta.env.VITE_APP_SENTRY == "TRUE") {
      //     Sentry.setUser({ email: this.accountInfo.value.email });
      //   }
    }

    return accountInfo.value as Creator;
  };

  const authenticate = async (token: string) => {
    AuthService.setToken(token);
    // if (WebSocketService.isConnected()) {
    //   WebSocketService.authenticate();
    // }
    return await getInfo(true);
  };

  const logout = async () => {
    AuthService.deleteToken();
    return await router.push({ name: "Login" });
  };

  const isAuthenticated = () => {
    return !!accountInfo.value || !!AuthService.getToken(); // Check if account info is available or if a token exists
  };

  return {
    accountInfo,
    authenticate,
    getInfo,
    logout,
    isAuthenticated,
  };
}
