import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import AuthenticationRoutes from "@/modules/authentication/router";
import ErrorRoutes from "@/modules/error/router";
import DealsRoutes from "@/modules/deals/router";
import DealRoutes from "@/modules/deal/router";
import PortfolioRoutes from "@/modules/account/portfolio/router";
import CertificationsRoutes from "@/modules/work/certifications/router";
import WalletRoutes from "@/modules/account/wallet/router";
import AccountSettingsRoutes from "@/modules/account/settings/router";
import ReviewsRoutes from "@/modules/reviews/router";
import AuthGuard from "@/helpers/AuthGuard";
import { useLoader } from "@/composables/useLoader";

const baseRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("@/modules/dashboard/views/TheDashboard.vue"),
        beforeEnter: AuthGuard.withCompleteOnboarding,
        meta: { title: "dashboard.meta", layout: "AuthenticatedLayout" },
      },
    ],
  },
];

const routes: RouteRecordRaw[] = baseRoutes.concat(
  AuthenticationRoutes,
  ErrorRoutes,
  DealsRoutes,
  DealRoutes,
  CertificationsRoutes,
  WalletRoutes,
  PortfolioRoutes,
  AccountSettingsRoutes,
  ReviewsRoutes
);

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return {
        ...savedPosition,
        behavior: "smooth",
      };
    }
    if (to.name === from.name && to.hash !== "" && to.hash !== from.hash) {
      return;
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        document.getElementById("layout-content")?.scroll({
          top: 0,
          behavior: "smooth",
        });

        resolve({
          top: 0,
          behavior: "smooth",
        });
      }, 200);
    });
  },
});

// Router hooks to show and hide loader on navigation
router.beforeEach((to, from, next) => {
  useLoader().startLoading(); // Start loader when route navigation begins
  next();
});

router.afterEach(() => {
  useLoader().stopLoading();
});

export default router;
