import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    firstName: "",
    lastName: "",
  }),
  getters: {
    fullName: (state) => `${state.firstName} ${state.lastName}`,
  },
  actions: {
    setFirstName(name: string) {
      this.firstName = name;
    },
    setLastName(name: string) {
      this.lastName = name;
    },
  },
});
