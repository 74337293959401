import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/account",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: {
      title: "account.settings.title",
      layout: "AuthenticatedLayout",
    },
    children: [
      {
        path: "/account/settings",
        name: "Settings",
        component: () => import("@/modules/account/settings/views/TheSettings.vue"),
      },
    ],
  },
];
