import { createGtm } from "@gtm-support/vue-gtm";
import useAccountHandler from "@/helpers/useAccountHandler";
import router from "@/router";

export default createGtm({
  id: import.meta.env.VITE_GTM_ID,
  vueRouter: router,
  vueRouterAdditionalEventData: async () => {
    if (useAccountHandler().isAuthenticated()) {
      const account = await useAccountHandler().getInfo();
      console.log(account, " account");
      return {
        user: {
          id: account.id,
          email: account.email,
          name: `${account.first_name} ${account.last_name}`,
          created: account.created_at,
          type: "creator",
        },
      };
    }
    return {};
  },
});
